<template>
    <div class="payment-page">
        <div v-if="payStatus == 1">
            <div class="header">
                <i class="el-icon-loading"></i>
                <span class="title">支付中...</span>
            </div>
            <el-card class="payment-info">
                <div class="info-row">
                    <span>产品名称</span>
                    <span>{{data.payInfo.proutName}}</span>
                </div>
                <div class="info-row">
                    <span>购买数量</span>
                    <span>1</span>
                </div>
                <div class="info-row">
                    <span>实际付款</span>
                    <span>¥{{data.payInfo.payAmt}}</span>
                </div>
            </el-card>
            <div class="instructions">
                <p>1. 如已支付成功，请点击“已完成支付”</p>
                <p>2. 如未支付成功，请点击“重新支付”</p>
            </div>
            <el-button type="primary" class="complete-button" @click="clickComplete">已完成支付</el-button>
            <el-button style="margin-left: 0px;" class="retry-button" @click="clickRetry">重新支付</el-button>
        </div>
        <pay-success :data="data" v-if="payStatus == 2"></pay-success>
        <pay-error  :data="data" v-if="payStatus == 3"></pay-error>
    </div>
</template>

<script>

import paySuccess from "@/components/pay/PaySuccess.vue";
import payError from "@/components/pay/PayError.vue";
export default {
    name: "AliPayIng",
    components: {
        paySuccess,
        payError
    },
    data(){
        return{
            data:{
                payInfo:{
                    proutName:'',
                    payAmt:0.00
                }
            },
            payStatus:0, //1支付中2已支付3错误
        }
    },
    mounted: function () {
        this.alipay();
    },
    methods:{
        clickComplete(){
            var that = this;
            let searchParams = new URLSearchParams(window.location.search);
            let sign = searchParams.get('sign');
            that.newApi.queryOrderInfo({sign:sign}).then((res) =>{
                if(res.isSuccess == 1){
                    that.data = res.data;
                    that.payStatus = 2;
                }
            })
        },
        clickRetry(){
          //刷新当前页面
            window.location.reload();
        },
        //支付宝支付
        alipay(){
            var that = this;
            let sign = this.$route.query.sign;
            that.newApi.aliPay({sign:sign}).then((res) => {
                if(res.isSuccess == 1){
                    that.data = res.data;
                    if(res.data.code == 200){
                        that.payStatus = 1;
                        window.location.href='alipays://platformapi/startapp?saId=10000007&qrcode=' + res.data.payInfo.pay_info;
                    }else if(res.data.code == 4003){
                        that.payStatus = 2;
                    }else{
                        that.payStatus = 3;
                    }
                }
            })
        },
    }
}
</script>

<style scoped>
*{
    margin: 0px;
    padding: 0px;
}
.payment-page {
    padding: 20px;
    text-align: center;
}
.header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.icon {
    font-size: 24px;
    margin-right: 8px;
}
.title {
    font-size: 18px;
    font-weight: bold;
}
.payment-info {
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 8px;
}
.info-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.instructions {
    margin-bottom: 20px;
    text-align: left;
    font-size: 14px;
}
.complete-button {
    width: 100%;
    margin-bottom: 10px;
    line-height: 30px;
}
.retry-button {
    width: 100%;
    line-height: 30px;
}


</style>